/* body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root > div {
  height: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:focus {
  outline: none;
}

ul {
  padding-inline-start: 0;
}

hr {
  opacity: 1;
} */

* {
    font-family: 'Work Sans';
}

html,
body,
#root,
#root > div {
    height: 100%;
    min-height: 100%;
}

#tidio-chat iframe {
    bottom: 0px !important;
}

@media only screen and (max-width: 980px) {
    #tidio-chat iframe {
        bottom: 0px !important;
        right: -5px !important;
    }
}