img[alt=image10] { width: 10%; display: block; margin: auto;}
img[alt=image20] { width: 20%; display: block; margin: auto;}
img[alt=image30] { width: 30%; display: block; margin: auto;}
img[alt=image40] { width: 40%; display: block; margin: auto;}
img[alt=image50] { width: 50%; display: block; margin: auto;}
img[alt=image60] { width: 60%; display: block; margin: auto;}
img[alt=image70] { width: 70%; display: block; margin: auto;}
img[alt=image80] { width: 80%; display: block; margin: auto;}
img[alt=image90] { width: 90%; display: block; margin: auto;}
img[alt=image100] { width: 100%; display: block; margin: auto;}

@media (max-width: 400px) {
  img[alt=image10] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image20] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image30] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image40] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image50] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image60] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image70] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image80] { width: 100%; height: auto; display: block; margin: auto;}
  img[alt=image90] { width: 100%; height: auto;  display: block; margin: auto;}
  img[alt=image100] { width: 100%; height: auto; display: block; margin: auto;}
}
.icon {
  padding-right: 5px;
}